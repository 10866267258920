@import "../../styles/";

.experience {
  margin: $default-spacing/2 0;

  &__detail {
    padding-bottom: 3px;
  }

  &__bullets {
    margin-left: 18px;
    list-style: outside;
    padding: $default-spacing/4 0;
  }

  &__technology {
    > ul {
      display: inline-block;
      list-style: none;
      margin: 0;
      padding: 0;

      > li {
        display: inline-block;
        margin: 0 3px 3px 0;
      }
    }
  }
}
