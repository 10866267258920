@import "../../styles";

.page-content {
  flex-grow: 1;
  padding-right: $default-spacing;
}

// lose flex layout for < medium screen sizes
@media screen {
  @media (max-width: 768px) {
    .page-content {
      padding-right: 0;
    }
  }
}

@media print {
  // fix for firefox bug with printing flex box elements
  @-moz-document url-prefix() {
    .page-content {
      padding-right: 0;
    }
  }
}
