@import "../../styles";

.detail-header {
  // font-size: 1.175em;
  padding: $default-spacing/4 0;
  
  > a {
    text-decoration: none;
  }
}
