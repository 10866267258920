.training {
  padding-bottom: 12px;

  &__dates {
    padding-bottom: 3px;
  }

  &__bullets {
    > ul {
      margin-left: 18px;
      list-style: outside;
    }
  }
}
