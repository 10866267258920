@import "../../styles";

.chip {
  display: inline-block;
  border-radius: 6px;
  background-color: $gray-200;
  padding: 3px 6px;

  &--small {
    font-size: .80em;
  }

  &--thin {
    background-color: transparent;
    border: 1px solid $gray-600;
  }
}
