@import "../../styles";

.sidebar {
  flex-shrink: 2;
  padding-left: $default-spacing;
}

// lose flex layout for < medium screen sizes
@media screen {
  @media (max-width: 768px) {
    .sidebar {
      padding-left: 0;
    }
  }
}

@media print {
  // fix for firefox bug with printing flex box elements
  @-moz-document url-prefix() {
    .sidebar {
      padding-left: 0;
    }
  }
}
