@import "../../styles/";

.header {
  display: flex;
  align-items: top;

  &__title {
    > h1 {
      font-size: 2.0em;
      margin: 0;
      padding: $default-spacing/2 0;
    }
  }

  &__left {
    flex-grow: 1;
  }

  &__avatar,
  &__avatar > img {
    width: 150px;
    @media screen {
      @media (max-width: 576px) {
        width: 75px;
      }
    }
  }

  &__contact-me {
    padding: $default-spacing/2 0;
    font-style: normal;
  }

  &__chip-row {
    > * {
      padding: $default-spacing/4 0;
      flex-basis: 250px;
    }
  }
}
