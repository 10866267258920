$gray-50: #FAFAFA;
$gray-100: #F5F5F5;
$gray-200: #EEEEEE;
$gray-300: #E0E0E0;
$gray-400: #BDBDBD;
$gray-500: #9E9E9E;
$gray-600: #757575;
$gray-700: #616161;
$gray-800: #424242;
$gray-900: #212121;

$blue-gray-50: #ECEFF1;
$blue-gray-100: #CFD8DC;
$blue-gray-200: #B0BEC5;
$blue-gray-300: #90A4AE;
$blue-gray-400: #78909C;
$blue-gray-500: #607D8B;
$blue-gray-600: #546E7A;
$blue-gray-700: #455A64;
$blue-gray-800: #37474F;
$blue-gray-900: #263238;
