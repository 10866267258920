.skills {
  // font-size: .9em;

  > ul {
    display: inline-block;
    list-style: none;
    margin: 0;
    padding: 0;

    > li {
      display: inline-block;
      margin-right: 6px;
      margin-bottom: 6px;
    }
  }
}
