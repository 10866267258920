@import "./styles";

*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html,
body {
  font-family: 'Rubik', sans-serif;
  font-size: $default-font-size;
  max-width: $max-body-width;
  margin: 0 auto;
  color: $gray-800;
  padding: $default-spacing;
}

@media screen {
  @media (max-width: 576px) {
    html,
    body {
      padding: $default-spacing/4;
    }
  }
  
  @media (max-width: 768px) {
    html,
    body {
      padding: $default-spacing/2;
    }      
  }
}

@media print {
  html,
  body {
    width: auto;
    max-width: unset;
    padding: 0;
    margin: 0;
    font-size: $print-font-size;
  }
}

a {
  color: inherit;
}

ul {
  list-style-position: inside;
}
