.section-header {
  // font-size: 1.5em;
  padding-bottom: 3px; 
  border-bottom: 3px solid #555;
  margin-bottom: 12px;
  
  > a {
    text-decoration: none;
  }
}
