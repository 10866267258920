.tidbit {
  display: flex;
  align-items: center;

  &__icon {
    flex-grow: 0;
  }
  
  &__text {
    flex-grow: 1;
    margin-left: 6px;
  }
}
